import React from 'react';
import Spline from '@splinetool/react-spline';

const App = () => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Spline
        scene="https://prod.spline.design/vYkuvKC6JtW17BWF/scene.splinecode"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default App;



